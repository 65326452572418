import { getBackgroundImages } from 'api/configurations.js';
import { getBlobSasPicture } from 'api/doctors.js';
import { imageUrlToBase64 } from 'calls/helpers/index.js';
import { default as DarkTheme } from 'calls/styles/DarkTheme.js';
import { default as LightTheme } from 'calls/styles/LightTheme.js';
import { VirtualBackgroundTypes } from 'calls/views/VirtualBackgroundProvider.jsx';
import {
	CallWorkflowType,
	CompanySettings,
	GeneralAndMenuSettings,
	IntegrationTypesSettings,
	LandingPrivateRoutes,
	TeamConfigurationIntegrationTypes,
	UserSettings,
	configurableAISettings,
	configurableMonitoringMenu,
	getConfig,
	getConfigurationWithDiffTypeKey,
} from 'constants/configurationEnums.js';
import {
	AndroidVersions,
	AppointmentStatus,
	AttachmentType,
	CallRequestType,
	CompanyRoleObjects,
	CompanyRoles,
	DeviceFamilyTypes,
	DeviceListLevel,
	Gender,
	IframeIntregrationsIds,
	InviteParticipantFailureReason,
	InviteStatuses,
	KeyCodes,
	MediaInputs,
	MediaPermissions,
	ParticipantState,
	RequestType,
	SectorTypes,
	TreeHierarchyType,
	UserRoles,
	UserTypes,
} from 'constants/enums.js';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { decode } from 'html-entities';
import html2canvas from 'html2canvas';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import {
	getBreadCrumbBasedHierarchy,
	getDepartmentBreadCrumb,
	getFloorBreadCrumb,
	getHospitalBreadCrumb,
	getHospitalFloorsBreadCrumb,
} from 'infrastructure/helpers/breadcrumbHelper.js';
import JsPDF from 'jspdf';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { isMobile, isTablet } from 'react-device-detect';
import { getEndPoint, getStartPoint } from 'services/aiDetections/fallDetectionConstants.js';
import { utils, writeFile } from 'xlsx';
import { v4 as uuidv4 } from 'uuid';

const treeImg = () => `${healthCareCdnUrl}treeview/`;

const regexForDecimalNumbers = new RegExp('^[0-9]{0,3}([,.][0-9]{0,2})?$');

export const isSessionEhr = () => {
	return sessionStorage.getItem('ehr.sid') !== null;
};

const hasRefTokenInQuery = () => {
	const { search, pathname } = window.location;
	const query = queryString.parse(search);
	const isAmwell = +query.iid === IframeIntregrationsIds.AMWELL;

	if (
		(query.refToken && (pathname.startsWith('/virtual-session/') || pathname.startsWith('/virtual-care-session/'))) ||
		isAmwell
	) {
		return query.refToken.toString();
	}
	return null;
};

let chosenStorage;

export const getStorage = () => {
	if (chosenStorage) {
		return chosenStorage;
	}
	let _storage = window !== window.parent ? sessionStorage : localStorage;

	const hasRefToken = hasRefTokenInQuery() || sessionStorage.getItem('ref_token');

	if (hasRefToken || isSessionEhr()) {
		chosenStorage = sessionStorage;
	} else {
		chosenStorage = _storage;
	}

	return chosenStorage;
};

export const isUUIDEmpty = uuid => {
	const emptyUUID = '00000000-0000-0000-0000-000000000000';
	if (uuid === null || uuid === undefined || uuid === emptyUUID) {
		return true;
	}
	return false;
};

export const getHierarchyByHelloDeviceId = (arr, id) => {
	return arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}

		if (+item.helloDeviceId === +id) {
			return getHierarchyFromBreadcrumb(item.breadcrumb);
		}
		if (item.subOptions) {
			return getHierarchyByHelloDeviceId(item.subOptions, id);
		}
	}, null);
};

export const getHierarchyFromBreadcrumb = breadcrumb => {
	let hierarchy = '';
	breadcrumb.forEach(({ name }, index) => {
		if (index > 2) {
			hierarchy = `${hierarchy} - ${name}`;
		} else {
			hierarchy = `${hierarchy}, ${name}`;
		}
	});
	return hierarchy.substring(2);
};
export const setCurrentHealthSystemIfNotExists = async healthSystem => {
	if (!getStorage().getItem('currentHealthSystemInfo')) {
		await setCurrentHealthSystem(healthSystem);
	}
};

export const setCurrentHealthSystem = async healthSystem => {
	const currentHealthSystemInfo = {
		currentHealthSystemId: healthSystem.id,
		currentHealthSystemName: healthSystem.name,
		currentRegionId: healthSystem.regions[0].id,
	};
	getStorage().setItem('currentHealthSystemInfo', JSON.stringify(currentHealthSystemInfo));
};

// Returns String: Hospital, Department, Floor, Room
export const getRoomLocation = breadcrumb => {
	let result = '';
	breadcrumb.forEach((item, index) => {
		result += breadcrumb.length - 1 !== index ? `${item.name}, ` : item.name;
	});
	return result;
};

export const findSectorById = (arr, id) => {
	if (!arr) {
		return null;
	}
	return arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}
		// Comparing whether the id given as argument is equal to any of the items declared in the array.
		if ([item.hospitalId, item.departmentId, item.floorId, item.roomId, item.id, item.helloDeviceId].includes(id)) {
			return item;
		}
		if (item.subOptions) {
			return findSectorById(item.subOptions, id);
		}
	}, null);
};

export const checkIfMediaDevicesPlugged = async () => {
	try {
		const devices = await navigator.mediaDevices.enumerateDevices();
		const pluggedDevices = {
			microphone: false,
			camera: false,
		};

		devices.forEach(device => {
			if (device.kind === MediaInputs.AUDIO_INPUT) {
				pluggedDevices.microphone = true;
			}

			if (device.kind === MediaInputs.VIDEO_INPUT) {
				pluggedDevices.camera = true;
			}
		});
		return pluggedDevices;
	} catch (error) {
		return { error };
	}
};

export const askForPermission = async permissionFor => {
	try {
		const response = await navigator.mediaDevices.getUserMedia(permissionFor);
		return { permission: MediaPermissions.GRANTED, response };
	} catch (error) {
		console.error(error);
		return { error };
	}
};

export const checkForPermission = async permissionFor => {
	try {
		return await navigator.permissions.query({ name: permissionFor });
	} catch (error) {
		return { error };
	}
};

export function isMacintosh() {
	return navigator.platform.includes('Mac');
}

export const decodeHtml = txt => decode(txt && txt.replace(/amp;/g, ''));

export const getParentSector = (arr, element) => {
	let parent = {};
	if (!element) {
		return parent;
	}
	if (element.type === SectorTypes.ROOM) {
		parent = findSectorById(arr, element.floorId);
	}
	if (element.type === SectorTypes.FLOOR) {
		parent = findSectorById(arr, element.departmentId);
	}
	if (element.type === SectorTypes.DEPARTMENT) {
		parent = findSectorById(arr, element.hospitalId);
	}
	return parent;
};

export const checkIfSectorNameExists = (currentSector, tree, sectorName) => {
	let sameLevelSectors;
	if (currentSector.type === SectorTypes.HOSPITAL) {
		sameLevelSectors = tree.filter(s => s.isNewOption === undefined);
		const hospitalExists = Object.entries(sameLevelSectors.length && sameLevelSectors[0]).length > 0;
		if (!hospitalExists) {
			return true;
		}
	} else {
		sameLevelSectors = getParentSector(tree, currentSector)?.subOptions?.filter(s => s.isNewOption === undefined);
	}
	return !sameLevelSectors?.some(sector => sector.name.toLowerCase() === sectorName.toLowerCase());
};

export const getSectorPath = sector => {
	const result = {};
	if (!sector) {
		return result;
	}
	if (sector.type === SectorTypes.HOSPITAL) {
		result[sector.regionId] = {};
	}
	if (sector.type === SectorTypes.DEPARTMENT) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
	}
	if (sector.type === SectorTypes.FLOOR) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId] = {};
	}
	if (sector.type === SectorTypes.ROOM) {
		result[sector.regionId] = {};
		result[sector.regionId][sector.hospitalId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId] = {};
		result[sector.regionId][sector.hospitalId][sector.departmentId][sector.floorId] = {};
	}
	return result;
};

const getHospitalDetails = ({ hospital, title, defaultDepartmentId = null }) => ({
	name: hospital.name,
	icon: 'business',
	imgIcon: `${treeImg()}Hospital.svg`,
	activeImgIcon: `${treeImg()}Hospital-active.svg`,
	link: `${hospital.id}`,
	tooltip: [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) ? title : false,
	type: 'hospital',
	subOptions: [],
	hospitalId: hospital.id,
	breadcrumb: getHospitalBreadCrumb(hospital),
	defaultDepartmentId,
	timezone: hospital.timezone,
	pin: hospital.helloSettingsPIN,
	scheduleNightVisionFrom: hospital?.scheduleNightVisionFrom,
	scheduleNightVisionTo: hospital?.scheduleNightVisionTo,
});

const getDepartmentDetails = ({ hospital, department, title }) => ({
	name: department.name,
	icon: 'account_balance',
	imgIcon: `${treeImg()}Department.svg`,
	activeImgIcon: `${treeImg()}Department-active.svg`,
	link: `${hospital.id}/department/${department.id}`,
	tooltip: [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) ? title : false,
	type: 'department',
	subOptions: [],
	departmentId: department.id,
	hospitalId: hospital.id,
	breadcrumb: getDepartmentBreadCrumb(hospital, department),
	unitPhoneNumber: department.unitPhoneNumber,
	whiteboardTypeId: department?.whiteboardTypeId,
	scheduleNightVisionFrom: department?.scheduleNightVisionFrom,
	scheduleNightVisionTo: department?.scheduleNightVisionTo,
});

const getFloorDetails = ({ hospital, department, floor, hierarchyTypeId }) => ({
	name: floor.name,
	icon: 'money',
	imgIcon: `${treeImg()}Floors.svg`,
	activeImgIcon: `${treeImg()}Floors-active.svg`,
	link: `${hospital.id}/department/${department.id}/floor/${floor.id}`,
	tooltip: [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) ? 'addRoom' : false,
	type: 'floor',
	subOptions: [],
	floorId: floor.id,
	departmentId: department.id,
	hospitalId: hospital.id,
	breadcrumb: [TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(hierarchyTypeId)
		? getFloorBreadCrumb(hospital, department, floor)
		: getHospitalFloorsBreadCrumb(hospital, department, floor),
	scheduleNightVisionFrom: floor?.scheduleNightVisionFrom,
	scheduleNightVisionTo: floor?.scheduleNightVisionTo,
});

const getRoomDetails = ({ hospital, department, floor, room, roomIndex, hierarchyTypeId }) => ({
	name: room.name,
	icon: 'meeting_room',
	imgIcon: `${treeImg()}Room.svg`,
	activeImgIcon: `${treeImg()}Room-active.svg`,
	link: `${hospital.id}/department/${department.id}/floor/${floor.id}/room/${room.id}`,
	type: 'room',
	subOptions: [],
	roomId: room.id,
	roomIndex,
	floorId: floor.id,
	floorName: floor.name,
	departmentId: department.id,
	departmentName: department.name,
	hospitalId: hospital.id,
	hospitalName: hospital.name,
	roomType: room.type,
	tooltip: room.helloDeviceId ? 'addToMonitoringView' : null,
	isHelloDevice: room?.deviceFamily !== DeviceFamilyTypes.TABLET,
	helloDeviceId: room.helloDeviceId ? parseInt(room.helloDeviceId, 10) : room.helloDeviceId,
	deviceFamily: room?.deviceFamily,
	breadcrumb: getBreadCrumbBasedHierarchy(hierarchyTypeId, hospital, department, floor, room),
	status: room.isOnline ? 'online' : 'offline',
	privacyMode: room.privacyMode,
	alexaServiceStatus: room.alexaServiceStatus,
	isMultiBed: room.isMultiBed,
	scheduleNightVisionFrom: room?.scheduleNightVisionFrom,
	scheduleNightVisionTo: room?.scheduleNightVisionTo,
});

export const buildTree = (healthSystem, isRoomsOnly = false) => {
	const treeData = [];
	if (isRoomsOnly) {
		healthSystem.hospitals.forEach((hospital, hospitalIndex) => {
			treeData.push({
				name: hospital.name,
				icon: 'business',
				imgIcon: `${treeImg()}Hospital.svg`,
				activeImgIcon: `${treeImg()}Hospital-active.svg`,
				link: `${hospital.id}`,
				type: 'hospital',
				subOptions: [],
				hospitalId: hospital.id,
				breadcrumb: getHospitalBreadCrumb(hospital),
				isRoomsOnly,
				timezone: hospital.timezone,
			});
			hospital.departments.forEach(department => {
				department.floors.forEach(floor => {
					floor.rooms.forEach((room, roomIndex) => {
						treeData[hospitalIndex].subOptions.push(
							getRoomDetails({
								hospital,
								department,
								floor,
								room,
								roomIndex,
								hierarchyTypeId: healthSystem.treeHierarchyTypeId,
							})
						);
					});
				});
			});
		});
	} else if (
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(healthSystem.treeHierarchyTypeId)
	) {
		healthSystem.hospitals.forEach((hospital, hospitalIndex) => {
			treeData.push(getHospitalDetails({ hospital, title: 'addDepartment' }));
			hospital.departments.forEach((department, departmentIndex) => {
				treeData[hospitalIndex].subOptions.push(getDepartmentDetails({ hospital, department, title: 'addFloor' }));
				department.floors.forEach((floor, floorIndex) => {
					treeData[hospitalIndex].subOptions[departmentIndex].subOptions.push(
						getFloorDetails({ hospital, department, floor, hierarchyTypeId: healthSystem.treeHierarchyTypeId })
					);
					floor.rooms.forEach((room, roomIndex) => {
						treeData[hospitalIndex].subOptions[departmentIndex].subOptions[floorIndex].subOptions.push(
							getRoomDetails({
								hospital,
								department,
								floor,
								room,
								roomIndex,
								hierarchyTypeId: healthSystem.treeHierarchyTypeId,
							})
						);
					});
				});
			});
		});
	} else if (healthSystem.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_DEPT_ROOM) {
		healthSystem.hospitals.forEach((hospital, hospitalIndex) => {
			treeData.push(getHospitalDetails({ hospital, title: 'addDepartment' }));
			hospital.departments.forEach((department, departmentIndex) => {
				treeData[hospitalIndex].subOptions.push(getDepartmentDetails({ hospital, department, title: 'addRoom' }));
				department.floors.forEach(floor => {
					floor.rooms.forEach((room, roomIndex) => {
						treeData[hospitalIndex].subOptions[departmentIndex].subOptions.push(
							getRoomDetails({
								hospital,
								department,
								floor,
								room,
								roomIndex,
								hierarchyTypeId: healthSystem.treeHierarchyTypeId,
							})
						);
					});
				});
			});
		});
	} else if (healthSystem.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
		healthSystem.hospitals.forEach((hospital, hospitalIndex) => {
			treeData.push(getHospitalDetails({ hospital, title: 'addFloor' }));
			hospital.departments.forEach(department => {
				department.floors.forEach((floor, floorIndex) => {
					treeData[hospitalIndex].subOptions.push(
						getFloorDetails({ hospital, department, floor, hierarchyTypeId: healthSystem.treeHierarchyTypeId })
					);
					floor.rooms.forEach((room, roomIndex) => {
						treeData[hospitalIndex].subOptions[floorIndex].subOptions.push(
							getRoomDetails({
								hospital,
								department,
								floor,
								room,
								roomIndex,
								hierarchyTypeId: healthSystem.treeHierarchyTypeId,
							})
						);
					});
				});
			});
		});
	} else if (healthSystem.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM) {
		healthSystem.hospitals.forEach((hospital, hospitalIndex) => {
			treeData.push(
				getHospitalDetails({
					hospital,
					title: 'addRoom',
					defaultDepartmentId:
						hospital.departments !== null && hospital.departments.length === 1 ? hospital.departments[0].id : null,
				})
			);
			hospital.departments.forEach(department => {
				department.floors.forEach(floor => {
					floor.rooms.forEach((room, roomIndex) => {
						treeData[hospitalIndex].subOptions.push(
							getRoomDetails({
								hospital,
								department,
								floor,
								room,
								roomIndex,
								hierarchyTypeId: healthSystem.treeHierarchyTypeId,
							})
						);
					});
				});
			});
		});
	}
	return treeData;
};

export const areSameObject = (object1, object2) =>
	object1.objectId.toString() === object2.objectId.toString() && object1.objectTypeId === object2.objectTypeId;
export const isDocumentAttachment = attachmentTypeId => attachmentTypeId === AttachmentType.DOCUMENT;
export const isVideoAttachment = attachmentTypeId => attachmentTypeId === AttachmentType.VIDEO;
export const isPictureAttachment = attachmentTypeId => attachmentTypeId === AttachmentType.PICTURE;
export const getAttachmentIcon = fileName => {
	const fileExtension = fileName.split('.').pop().toLowerCase();
	switch (fileExtension) {
		case 'jpg':
		case 'jpeg':
		case 'png':
		case 'bmp':
			return `${healthCareCdnUrl}requests/image.svg`;
		case 'txt':
			return `${healthCareCdnUrl}requests/text.svg`;
		case 'doc':
		case 'docx':
			return `${healthCareCdnUrl}requests/doc.svg`;
		case 'xls':
		case 'xlsx':
			return `${healthCareCdnUrl}requests/xlsx.svg`;
		case 'pdf':
			return `${healthCareCdnUrl}requests/pdf.svg`;
		case 'ppt':
		case 'pptx':
			return `${healthCareCdnUrl}requests/pptx.svg`;
		case 'mpg':
		case 'mp4':
		case 'mkv':
		case '3gp':
		case 'm4v':
		case 'mov':
			return `${healthCareCdnUrl}requests/video.svg`;
		default:
			return 'invalidFileType';
	}
};

export const getGender = genderId => {
	switch (genderId) {
		case Gender.MALE:
			return { description: translate('male'), icon: `${healthCareCdnUrl}sign-up/male.svg` };
		case Gender.FEMALE:
			return { description: translate('female'), icon: `${healthCareCdnUrl}sign-up/female.svg` };
		case Gender.NON_BINARY:
			return { description: translate('nonBinary'), icon: `${healthCareCdnUrl}sign-up/non-binary.svg?v2` };
		default:
			return { description: '', icon: '' };
	}
};

export const removeSpecialCharacters = fileName => {
	return fileName.replace(/[^\w\s]/gi, '');
};
export const takeScreenshot = videoElement => {
	if (!videoElement) {
		return;
	}
	const canvas = document.createElement('canvas');
	canvas.width = window.innerWidth;
	canvas.height = window.innerHeight;
	const ctx = canvas.getContext('2d');
	ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
	const anchor = document.createElement('a');
	anchor.href = canvas.toDataURL('image/jpeg');
	anchor.download = `${+new Date()}${Math.random()}.jpeg`;
	anchor.hidden = true;
	document.body.appendChild(anchor);
	anchor.click();
	anchor.remove();
};

export const getInitialsColor = letter => {
	const obj = {};
	if (!letter) {
		obj.color = `4772f3`;
		obj.backgroundColor = `d0e2fd`;
		return obj;
	}
	const letterToLowerCase = letter.toLowerCase();
	switch (letterToLowerCase) {
		case 'a':
		case 'n': {
			obj.color = `4772f3`;
			obj.backgroundColor = `d0e2fd`;
			break;
		}
		case 'b':
		case 'o': {
			obj.color = `e361c9`;
			obj.backgroundColor = `edd2fc`;
			break;
		}
		case 'c':
		case 'p': {
			obj.color = `eb605e`;
			obj.backgroundColor = `f9dcd2`;
			break;
		}
		case 'd':
		case 'q': {
			obj.color = `15a6ff`;
			obj.backgroundColor = `d2f0fc`;
			break;
		}
		case 'e':
		case 'r': {
			obj.color = `44ccb5`;
			obj.backgroundColor = `d2f7ec`;
			break;
		}
		case 'f':
		case 's': {
			obj.color = `dd995b`;
			obj.backgroundColor = `f7e4d2`;
			break;
		}
		case 'g':
		case 't': {
			obj.color = `e2619c`;
			obj.backgroundColor = `f9d2f8`;
			break;
		}
		case 'h':
		case 'u': {
			obj.color = `73c140`;
			obj.backgroundColor = `d2f7d6`;
			break;
		}
		case 'i':
		case 'v': {
			obj.color = `8a64dd`;
			obj.backgroundColor = `e2d2f9`;
			break;
		}
		case 'j':
		case 'w': {
			obj.color = `d3f4e8`;
			obj.backgroundColor = `41837a`;
			break;
		}
		case 'k':
		case 'x': {
			obj.color = `b94557`;
			obj.backgroundColor = `f4d3d3`;
			break;
		}
		case 'l':
		case 'y': {
			obj.color = `fcfaef`;
			obj.backgroundColor = `d2f7d6`;
			break;
		}
		case 'm':
		case 'z': {
			obj.color = `70a8b5`;
			obj.backgroundColor = `e8f5f9`;
			break;
		}
		default: {
			obj.color = `4772f3`;
			obj.backgroundColor = `d0e2fd`;
			break;
		}
	}
	return obj;
};

export const getCoverElementDimensions = elementToCover => {
	let result = {};
	if (!elementToCover) {
		return result;
	}
	const { width } = elementToCover.getBoundingClientRect();
	result = { width, height: (width * 9) / 16 };
	return result;
};

export const reOrderRecentList = (conversations, items) => {
	if (conversations.length === 0) {
		return items;
	}
	const myItems = [];
	let foundItem = null;
	items.forEach(patient => {
		const foundConversation = conversations.find(conversation => {
			let result = null;
			if (conversation.typeId === 0 && patient.owner.id === Number(conversation.contact.objectId)) {
				result = conversation;
			}
			return result;
		});
		if (foundConversation) {
			foundItem = patient;
			foundItem.time = foundConversation.lastActivity.time;
			myItems.push(foundItem);
		} else {
			myItems.push(patient);
		}
	});
	myItems.sort((a, b) => b.time - a.time);
	return myItems;
};

export const convertSecondsToHoursFormat = (secondsString, showSeconds = false) => {
	if (!secondsString) {
		return 'N/A';
	}
	const seconds = parseInt(secondsString, 10);
	const hours = seconds / 3600;
	const hoursInteger = Math.floor(hours);
	const minutesInteger = Math.floor((hours - hoursInteger) * 60);
	const secondsInteger = seconds % 60;
	return `${hoursInteger}h ${minutesInteger.toString().padStart(2, '0')}m ${
		showSeconds ? `${secondsInteger.toString().padStart(2, '0')}s` : ''
	}`;
};

export const convertHoursFormatToSeconds = date => {
	const hours = parseInt(date.substring(0, date.indexOf('h')), 10);
	const minutes = parseInt(date.substring(date.indexOf(' '), date.indexOf('m')), 10);
	return hours * 3600 + minutes * 60;
};

export const handleOnKeyDownNumeric = (event, allowPeriod = false) => {
	if (
		[
			KeyCodes.MINUS,
			KeyCodes.NUMPAD_PERIOD,
			KeyCodes.NUMPAD_ADD,
			KeyCodes.NUMPAD_SUBTRACT,
			!allowPeriod && KeyCodes.PERIOD,
			KeyCodes.PLUS,
			KeyCodes.LETTER_E,
		].includes(event.which)
	) {
		event.preventDefault();
	}
};

export const allowOnlyNumbersOrLetters = event => {
	const { value } = event.target;

	if (!event.key.match(/[a-zA-Z0-9,]/) || (event.key === ',' && value[value.length - 1] === ',')) {
		event.preventDefault();
	}
};

export const doNotAllowSpaceAsFirstCharacter = event => {
	if (!event.target.value && event.which === KeyCodes.SPACE) {
		event.preventDefault();
	}
};

export const handleOnKeyDownCellPhoneNumbers = event => {
	if (
		[
			KeyCodes.MINUS,
			KeyCodes.NUMPAD_PERIOD,
			KeyCodes.NUMPAD_ADD,
			KeyCodes.NUMPAD_SUBTRACT,
			KeyCodes.PERIOD,
			KeyCodes.LETTER_E,
			KeyCodes.PLUS,
		].includes(event.which) ||
		(event.which >= KeyCodes.LETTER_A && event.which <= KeyCodes.LETTER_Z)
	) {
		event.preventDefault();
	}
};

export const stringToCamelCase = str => {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+|\(|\)/g, '');
};

export const isCamelCase = str => {
	const camelCaseRegex = /^[a-z]+([A-Z][a-z]*)*$/;
	return camelCaseRegex.test(str);
};

export const capitalizeFirstLetter = string => {
	if (typeof string !== 'string') return '';
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPreferredLanguageLocale = () => {
	return getStorage().getItem('preferredLanguage');
};

export const setPreferredLanguageLocale = preferredLanguage => {
	getStorage().setItem('preferredLanguage', preferredLanguage);
};

export const setConversationMembersInfo = (members, conversationId) => {
	const conversationMembersInfo = getStorage().getItem('conversationMembersInfo');
	if (conversationMembersInfo) {
		const parsedConversationMembersInfo = JSON.parse(conversationMembersInfo);
		parsedConversationMembersInfo[conversationId] = members;
		getStorage().setItem('conversationMembersInfo', JSON.stringify(parsedConversationMembersInfo));
	} else {
		getStorage().setItem('conversationMembersInfo', JSON.stringify({ [conversationId]: members }));
	}
};

export const getConversationMembersInfo = conversationId => {
	const conversationInfo = JSON.parse(getStorage().getItem('conversationMembersInfo')) ?? {};
	return conversationInfo[conversationId] ?? [];
};

export const getPositionOfMainSkeleton = (skeletonArray, videoRef) => {
	let position = null;

	const x1 = videoRef.clientWidth * getStartPoint();
	const x2 = videoRef.clientWidth * getEndPoint();
	for (let index = 0; index < skeletonArray.length; index += 1) {
		let counter = 0;
		skeletonArray[index].keypoints.forEach(keypoint => {
			if (keypoint.position.x >= x1 && keypoint.position.x <= x2) {
				counter += 1;
			}
		});
		if (counter > skeletonArray[index].keypoints.length / 2) {
			position = index;
			break;
		}
	}
	return position;
};

export const adjustPositions = (skeletons, clientWidth, clientHeight) => {
	const newSkeletons = [...skeletons];
	newSkeletons.forEach(item => {
		item.keypoints.forEach(keyPoint => {
			const position = { ...keyPoint.position };
			position.x = keyPoint.position.x * clientWidth;
			position.y = keyPoint.position.y * clientHeight;
			// eslint-disable-next-line no-param-reassign
			keyPoint.position = position;
		});
	});
	return newSkeletons;
};

export const getBaseUrl = () => {
	if (window.location.pathname.startsWith('/health-system')) {
		return '/health-system';
	}
	if (window.location.pathname.startsWith('/monitoring')) {
		return '/monitoring';
	}
	if (window.location.pathname.startsWith('/visitors')) {
		return '/visitors';
	}
	return '';
};

export const findDeviceById = (arr, id) => {
	// eslint-disable-next-line consistent-return
	return arr.reduce((accumulator, item) => {
		if (accumulator) {
			return accumulator;
		}
		if (+item.helloDeviceId === +id) {
			return item;
		}
		if (item.subOptions) {
			return findDeviceById(item.subOptions, +id);
		}
	}, null);
};

export const findRoomOnHs = (hs, deviceId) => {
	let room;
	hs.hospitals.forEach(hos => {
		hos.departments.forEach(dep => {
			dep.floors.forEach(fl => {
				if (!room) {
					// eslint-disable-next-line eqeqeq
					room = fl.rooms.find(x => x.helloDeviceId == deviceId);
				}
			});
		});
	});
	return room;
};

export const skipDuplicatedObjects = (objects, key) => {
	const uniqueObjects = new Map();

	objects.forEach(obj => {
		let id = null;
		if (key) {
			id = obj[key];
		} else {
			id = obj.text.props?.id ?? obj.text.id;
		}
		uniqueObjects.set(id, obj);
	});

	return [...uniqueObjects.values()];
};

export const mapDevicesToPatients = (patients, devices) => {
	patients.forEach(patient => {
		// eslint-disable-next-line no-param-reassign
		patient.devices = devices.reduce((acc, device) => {
			if (device.userId === patient.userId && device.deviceInfo) {
				acc.push(device.deviceInfo);
			}
			return acc;
		}, []);
	});
	return patients;
};

export const isMobileOrTablet = () => isMobile || isTablet;

export const isEmailValid = email => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const getFirstName = fullName => fullName.split(' ').slice(0, -1).join(' ');

export const getLastName = fullName => fullName.split(' ').slice(-1).join(' ');

export const getDeviceAssignedRoom = (tree, deviceId) => {
	const option = {};

	tree.forEach(region => {
		const hospitals = region.subOptions;
		hospitals.forEach(hospital => {
			const departments = hospital.subOptions;
			departments.forEach(department => {
				const floors = department.subOptions;
				floors.forEach(floor => {
					const rooms = floor.subOptions;
					rooms.forEach(room => {
						if (room.helloDeviceId === deviceId) {
							option.roomName = room.name;
							option.floorName = floor.name;
							option.departmentName = department.name;
							option.hospitalName = hospital.name;
							option.regionName = region.name;
						}
					});
				});
			});
		});
	});

	return option;
};

export const validateAge = dateOfBirth => {
	return moment().diff(dateOfBirth, 'years', false) <= 120;
};

export const getUserLocation = async (onSuccess, onError) => {
	navigator.geolocation.getCurrentPosition(onSuccess, onError, {
		enableHighAccuracy: true,
		timeout: 5000,
		maximumAge: 0,
	});
};

export const calcDistanceInMiles = (location1, location2) => {
	if (!location1 || !location2) {
		return null;
	}
	const radiusOfEarth = 3958.8; // Radius of the Earth in miles
	const rlat1 = location1.latitude * (Math.PI / 180); // Convert degrees to radians
	const rlat2 = location2.latitude * (Math.PI / 180); // Convert degrees to radians
	const difflat = rlat2 - rlat1; // Radian difference (latitudes)
	const difflng = (location2.longitude - location1.longitude) * (Math.PI / 180); // Radian difference (longitudes)
	const distance = (
		2 *
		radiusOfEarth *
		Math.asin(
			Math.sqrt(
				Math.sin(difflat / 2) * Math.sin(difflat / 2) +
					Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflng / 2) * Math.sin(difflng / 2)
			)
		)
	).toFixed(2);
	return parseFloat(distance);
};

export const searchSectors = (arr, name, sectors = []) => {
	// eslint-disable-next-line consistent-return
	arr.forEach(item => {
		if (!name) {
			// eslint-disable-next-line no-param-reassign
			item.hidden = false;
			// eslint-disable-next-line no-param-reassign
			item.matchesSearch = false;
		}
		if (item.name?.toLowerCase().includes(name.toLowerCase())) {
			// eslint-disable-next-line no-param-reassign
			item.hidden = false;
			// eslint-disable-next-line no-param-reassign
			item.matchesSearch = true;
			sectors.push(item);
		} else {
			// eslint-disable-next-line no-param-reassign
			item.hidden = true;
			// eslint-disable-next-line no-param-reassign
			item.matchesSearch = false;
		}
		if (item.subOptions && item.subOptions.length > 0) {
			return searchSectors(item.subOptions, name, sectors);
		}
	});
	return sectors;
};

export const showPath = (tree, arr) => {
	arr.forEach(item => {
		if (item.hospitalId) {
			const hospital = findSectorById(tree, item.hospitalId);
			hospital.hidden = false;
			hospital.matchesSearch = true;
		}

		if (item.departmentId) {
			const department = findSectorById(tree, item.departmentId);
			department.hidden = false;
			department.matchesSearch = true;
		}

		if (item.floorId) {
			const floor = findSectorById(tree, item.floorId);
			floor.hidden = false;
			floor.matchesSearch = true;
		}
	});
};

export const getStatus = statusId => {
	switch (statusId) {
		case AppointmentStatus.ACCEPTED:
			return 'accepted';
		case AppointmentStatus.PENDING:
			return 'pending';
		case AppointmentStatus.REJECTED:
			return 'rejected';
		case AppointmentStatus.CANCELED:
			return 'canceled';
		case AppointmentStatus.COMPLETED:
			return 'completed';
		default:
			return '';
	}
};

export const capitalizeLetterAndAddSpaces = (str = '') =>
	str.replace(/\b\w/g, c => c.toUpperCase()).replace(/([a-z])([A-Z])/g, '$1 $2');

export const getConfigurableRaiseAlert = () =>
	getStorage().getItem('configurableRaiseAlert') ? JSON.parse(getStorage().getItem('configurableRaiseAlert')) : false;

export const savePdf = async (ref, fileName, type, config, margin = [0.5, 0.5]) => {
	const charts = ref?.current;

	html2canvas(charts, config).then(canvas => {
		const image = { type, quality: 0.98 };

		const imgWidth = 8.5;
		let pageHeight = 11;

		const innerPageWidth = imgWidth - margin[0] * 2;
		const innerPageHeight = pageHeight - margin[1] * 2;

		// Calculate the number of pages.
		const pxFullHeight = canvas.height;
		const pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
		const nPages = Math.ceil(pxFullHeight / pxPageHeight);

		// Define pageHeight separately so it can be trimmed on the final page.
		pageHeight = innerPageHeight;

		// Create a one-page canvas to split up the full image.
		const pageCanvas = document.createElement('canvas');
		const pageCtx = pageCanvas.getContext('2d');
		pageCanvas.width = canvas.width;
		pageCanvas.height = pxPageHeight;

		// Initialize the PDF.
		const pdf = new JsPDF('p', 'in', [8.5, 11]);

		for (let page = 0; page < nPages; page += 1) {
			// Trim the final page to reduce file size.
			if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
				pageCanvas.height = pxFullHeight % pxPageHeight;
				pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
			}

			// Display the page.
			const w = pageCanvas.width;
			const h = pageCanvas.height;
			pageCtx.fillStyle = 'white';
			pageCtx.fillRect(0, 0, w, h);
			pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

			// Add the page to the PDF.
			if (page > 0) pdf.addPage();
			const imgData = pageCanvas.toDataURL(`image/${image.type}`, image.quality);
			pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
		}

		pdf.save(fileName);
	});
};

export const dataURLtoFile = (dataurl, filename) => {
	const arr = dataurl.split(',');
	const mime = arr[0].match(/:(.*?);/)[1];
	const bstr = atob(arr[1]);
	let n = bstr.length;
	const u8arr = new Uint8Array(n);
	/* eslint-disable no-plusplus */
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
};

export const validateMaxLength = (event, maxLength) => {
	handleOnKeyDownNumeric(event);
	if (
		event.target.value.length === maxLength &&
		![
			KeyCodes.BACK_SPACE,
			KeyCodes.LETTER_A,
			KeyCodes.LEFT_ARROW,
			KeyCodes.UP_ARROW,
			KeyCodes.DOWN_ARROW,
			KeyCodes.RIGHT_ARROW,
			event.ctrlKey,
		].includes(event.which)
	) {
		event.preventDefault();
	}
};

export const downloadFileFromUrl = (imgLink, originalFileName) => {
	const link = document.createElement('a');
	link.setAttribute('href', imgLink);
	link.setAttribute('download', originalFileName);
	document.body.appendChild(link);
	link.click();
	link.remove();
};

export const getAppVersion = content => content.split('-')[0];

export const getCodeNameByOsVersion = osVersion => {
	const version = AndroidVersions.find(({ apiLevels }) => apiLevels.includes(+osVersion));
	return version?.codeName ?? 'N/A';
};

export const capitalizeEveryFirstLetter = str => {
	const arr = str.split(' ');
	arr.forEach((item, index) => {
		arr[index] = item.charAt(0).toUpperCase() + item.slice(1);
	});
	return arr.join(' ');
};

export const validateDecimalNumber = (event, maxLength) => {
	handleOnKeyDownNumeric(event, true);
	if (
		event.target.value.length === maxLength &&
		![
			KeyCodes.BACK_SPACE,
			KeyCodes.LETTER_A,
			KeyCodes.LEFT_ARROW,
			KeyCodes.UP_ARROW,
			KeyCodes.DOWN_ARROW,
			KeyCodes.RIGHT_ARROW,
			event.ctrlKey,
		].includes(event.which)
	) {
		event.preventDefault();
	}
};

export const isDecimal = value => {
	return regexForDecimalNumbers.test(value);
};

export const getNursePoolingStatus = () => getStorage().getItem('nursePoolingStatus');

export const setDoctorSpecialty = specialty => {
	getStorage().setItem('doctorSpecialty', specialty);
};

export const getDoctorSpecialty = () => getStorage().getItem('doctorSpecialty');

export const createArrayWithDefaultValues = endIndex => {
	const newArray = [];
	for (let startIndex = 0; startIndex < endIndex; startIndex++) {
		newArray.push(startIndex);
	}
	return newArray;
};
export const setUserNotAuthorized = status => {
	getStorage().setItem('notAuthorized', status);
};

export const isUserUnauthorized = () => getStorage().getItem('notAuthorized');

export const getLevelDevices = (option, includeRoom = false) => {
	const devices = [];
	switch (option.type) {
		case SectorTypes.HOSPITAL: {
			const departments = option.subOptions;
			departments.forEach(department => {
				const floors = department.subOptions;
				floors.forEach(floor => {
					const rooms = floor.subOptions;
					rooms.forEach(room => {
						if (room.helloDeviceId !== undefined) {
							devices.push(includeRoom ? room : room.helloDeviceId);
						}
					});
				});
			});
			return devices;
		}
		case SectorTypes.DEPARTMENT: {
			const floors = option.subOptions;
			floors.forEach(floor => {
				const rooms = floor.subOptions;
				rooms.forEach(room => {
					if (room.helloDeviceId) {
						devices.push(includeRoom ? room : room.helloDeviceId);
					}
				});
			});
			return devices;
		}
		case SectorTypes.FLOOR: {
			const rooms = option.subOptions;
			rooms.forEach(room => {
				if (room.helloDeviceId) {
					devices.push(includeRoom ? room : room.helloDeviceId);
				}
			});
			return devices;
		}
		default:
			return devices;
	}
};

export const isContinueAsSelected = () => getStorage().getItem('isContinueAs') != null;

export const getRoomTypeIdsToFilterWith = (rooms, userRole) => {
	let roomTypes = [];
	if (userRole === UserRoles.DOCTOR) {
		roomTypes = [RoomTypes.DOCTOR.type];
	}
	if (userRole === UserRoles.DIGITAL_CLINICIAN) {
		roomTypes = [RoomTypes.DIGITAL_CLINIC.type, RoomTypes.FRONT_DESK.type];
	}
	if (userRole === UserRoles.NURSE) {
		roomTypes = [
			RoomTypes.LABORATORY.type,
			RoomTypes.INFUSION_ROOM.type,
			RoomTypes.INJECTION_ROOM.type,
			RoomTypes.RADIOLOGY.type,
		];
	}
	return rooms.filter(item => roomTypes.includes(item.type));
};

export const downloadReturnReport = (reportRef, name) => {
	const fileName = name;
	const fileType = 'jpeg';
	const configurations = { useCORS: true, allowTaint: true, scrollY: 0 };
	savePdf(reportRef, fileName, fileType, configurations, [0.2, 0.2]);
};

export const getLevelIdByType = type => {
	let result;
	switch (type) {
		case SectorTypes.HOSPITAL: {
			result = DeviceListLevel.HOSPITAL;
			break;
		}
		case SectorTypes.DEPARTMENT: {
			result = DeviceListLevel.DEPARTMENT;
			break;
		}
		case SectorTypes.FLOOR: {
			result = DeviceListLevel.FLOOR;
			break;
		}
		case SectorTypes.ROOM: {
			result = DeviceListLevel.ROOM;
			break;
		}
		default: {
			result = null;
		}
	}
	return result;
};

export const getLevelDescriptionById = type => {
	switch (type) {
		case DeviceListLevel.HEALTH_SYSTEM: {
			return SectorTypes.HEALTH_SYSTEM;
		}
		case DeviceListLevel.HOSPITAL: {
			return SectorTypes.HOSPITAL;
		}
		case DeviceListLevel.DEPARTMENT: {
			return SectorTypes.DEPARTMENT;
		}
		case DeviceListLevel.FLOOR: {
			return SectorTypes.FLOOR;
		}
		case DeviceListLevel.ROOM: {
			return SectorTypes.ROOM;
		}
		default: {
			return SectorTypes.COMPANY;
		}
	}
};

export const getPort = url => {
	const parts = url.split(':'),
		port = parseInt(parts[parts.length - 1], 10);
	if (parts[0] === 'http' && (Number.isNaN(port) || parts.length < 3)) {
		return '';
	}
	if (parts[0] === 'https' && (Number.isNaN(port) || parts.length < 3)) {
		return '';
	}
	if (parts.length === 1 || Number.isNaN(port)) return '';
	return port;
};

export const getUserRoleId = userRole => {
	if (!userRole) {
		return null;
	}
	return CompanyRoleObjects.find(item => item.role.toLowerCase() === userRole?.toLowerCase())?.id;
};

export const getProperUrl = (configs, isPrimaryCare, role = getUserRole()) => {
	const nonChangeableItem = getLandingPrivateRoute(isPrimaryCare);
	if (nonChangeableItem) {
		return nonChangeableItem;
	}

	const adminMenu = getConfigurationMenu(configs.adminConfigurableMenu, role);
	const myMenu = getConfigurationMenu(configs.configurableMenu, role);
	let availableURLs = [];
	const isMyMenu = ![
		UserRoles.NURSE,
		UserRoles.DIGITAL_CLINICIAN,
		UserRoles.DOCTOR,
		UserRoles.VIRTUAL_SITTER,
		UserRoles.SUPER_USER,
	].includes(role);
	if (isMyMenu) {
		Object.entries(myMenu).forEach(([, item]) => {
			if (item.value && !item.isGeneralSetting) {
				availableURLs.push(item);
			}
		});
	} else {
		Object.entries(adminMenu).forEach(([key, item]) => {
			if (myMenu[key].value && item.value && !item.isGeneralSetting) {
				availableURLs.push(item);
			}
		});
	}

	const defaultOnlyForPrimaryCare = [LandingPrivateRoutes.CHECK_IN_DC, LandingPrivateRoutes.NURSE_HOME];

	const result = availableURLs
		.filter(item => !defaultOnlyForPrimaryCare.includes(item.reDirectUrl) && !item.parentDependency)
		.sort((a, b) => a.orderNo - b.orderNo);
	return result.length > 0 ? result[0].reDirectUrl : getDefaultRedirectLink(isPrimaryCare);
};

const getLandingPrivateRoute = isPrimaryCare => {
	let result = null;
	switch (getUserRole()) {
		case UserRoles.NURSE: {
			result = isPrimaryCare ? LandingPrivateRoutes.NURSE_HOME : '';
			break;
		}
		case UserRoles.DOCTOR: {
			result = isPrimaryCare ? LandingPrivateRoutes.DOCTOR_HOME : '';
			break;
		}
		case UserRoles.DIGITAL_CLINICIAN: {
			result = LandingPrivateRoutes.CHECK_IN_DC;
			break;
		}
		default: {
			return null;
		}
	}
	return result;
};

export const getDefaultRedirectLink = isPrimaryCare => {
	const role = getUserRole();
	let url;
	switch (role) {
		case UserRoles.VIRTUAL_SITTER: {
			url = '/monitoring';
			break;
		}
		case UserRoles.SUPER_ADMIN: {
			url = '/companies.js';
			break;
		}
		case UserRoles.ADMIN:
		case UserRoles.SUPER_USER: {
			url = '/health-system';
			break;
		}
		case UserRoles.NURSE: {
			url = isPrimaryCare ? '/nurse-home' : '/health-system';
			break;
		}
		case UserRoles.PATIENT: {
			url = '/patient-home';
			break;
		}
		case UserRoles.DIGITAL_CLINICIAN: {
			url = '/check-in-dc';

			break;
		}
		case UserRoles.DOCTOR: {
			url = '/doctor-home';
			break;
		}
		case UserRoles.VISITOR: {
			url = '/devices';
			break;
		}
		default: {
			url = '';
		}
	}
	return url;
};

export const isCanvasEmpty = canvas => {
	const blank = document.createElement('canvas');
	blank.width = canvas.width;
	blank.height = canvas.height;

	return canvas.toDataURL() === blank.toDataURL();
};
export const isMobileOrTabletDevice = () =>
	'ontouchstart' in document.documentElement && 'orientation' in window && navigator.maxTouchPoints > 0;

export const isSmartLauncher = () => {
	return sessionStorage.getItem('ehr.sid') === 'smart';
};

export const setIsAdUser = value => {
	getStorage().setItem('isCompanyAdUser', value);
};

export const isAdUser = () => getStorage().getItem('isCompanyAdUser') === 'true';

export const setHasCompanyAdConfigurations = value => getStorage().setItem('hasCompanyAdConfigurations', value);

export const hasCompanyAdConfigurations = () => getStorage().getItem('hasCompanyAdConfigurations') === 'true';

export const getSomeConfigurationsValues = (configs, keys) => (configs ? keys.some(key => configs[key]?.value) : false);

export const getCallsButtonColor = darkMode => {
	return darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive;
};

export const getHealthSystemDevices = healthSystem => {
	const result = { busy: [], online: [], privacy: [], pairedRemote: [] };
	healthSystem.hospitals.forEach(hospital => {
		hospital.departments.forEach(department => {
			department.floors.forEach(floor => {
				floor.rooms.forEach(room => {
					if (room.callState === ParticipantState.CONNECTED.type) {
						result.busy.push(+room.helloDeviceId);
					}
					if (room.isOnline) {
						result.online.push(+room.helloDeviceId);
					}
					if (room.aiPrivacyStatus) {
						result.privacy.push(+room.helloDeviceId);
					}
					if (room.pairedRemote) {
						result.pairedRemote.push(+room.helloDeviceId);
					}
				});
			});
		});
	});
	return result;
};
export const getConfigurationMenu = (menuObject, userRole = getUserRole()) => menuObject[userRole] || {};

export const getConfigurationValue = config => (config ? config.value : false);

export const getConfigurationVariant = config => (config ? config.variant : '');

export const getRoleConfigurationValue = (configs, key) => (configs ? configs[key] : false);

export const getSomeRoleConfigurationsValues = (configs, keys) => (configs ? keys.some(key => configs[key]) : false);

export const getMonitoringConfigurationVariant = (config, key) =>
	config ? config?.variant : configurableMonitoringMenu(getUserRole())[key].variant;

export const updateConfigsList = (adminRoomSettings, myRoomSettings) => {
	const response = { configs: {}, error: null };
	const allMenus = {};

	if (adminRoomSettings.error) {
		response.error = adminRoomSettings.error.message;
	} else {
		adminRoomSettings.settings.forEach(item => {
			const config = configurableMonitoringMenu(getUserRole())[item.settingTypeId];
			if (config) {
				config.value = JSON.parse(item.value);
				config.isLocked = item.isLocked;
				if (item.variant && config.options) {
					config.variant = config.options.find(opt => opt.value === item.variant);
				}
				allMenus[item.settingTypeId] = config;
			}
		});
	}
	if (myRoomSettings.error) {
		response.error = myRoomSettings.error.message;
	} else {
		myRoomSettings.forEach(item => {
			if (allMenus[item.preferenceTypeId] && !allMenus[item.preferenceTypeId].isLocked) {
				allMenus[item.preferenceTypeId].value = JSON.parse(item.value);
			}
		});
	}

	response.configs = allMenus;
	return response;
};

export const clearStorageAndKeepCookiePrefrences = () => {
	const cookiePreferences = getStorage().getItem('cookiePreferences');
	const cookieAnswer = getStorage().getItem('cookieAnswer');
	getStorage().clear();
	getStorage().setItem('cookiePreferences', cookiePreferences);
	getStorage().setItem('cookieAnswer', cookieAnswer);
};

export const generateMRN = (length = 10) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
};

export const sortDevicesByTreeRoomsOrder = (arrayToSort, orderArray) =>
	orderArray.reduce((acc, obj) => {
		const foundItem = arrayToSort.find(item => item.roomId === obj.id);
		if (foundItem) {
			acc.push(foundItem);
		}
		return acc;
	}, []);

export const toPascalCase = string => {
	const str = string.trim();
	const words = str.split(/\s+|\W+/);
	const pascalCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
	const pascalCaseString = pascalCaseWords.join('');
	return pascalCaseString;
};

export const reorderObjects = (originalObject, orderArray) => {
	const newObject = {};

	orderArray.forEach(key => {
		if (key in originalObject) {
			newObject[key] = originalObject[key];
		}
	});

	return newObject;
};

export const getLettersBasedIndex = index => {
	const alphabetLowercase = Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i));
	if (index < 0 || index >= alphabetLowercase.length) {
		return null;
	}
	return alphabetLowercase[index];
};

export const downloadMultipleFiles = (files, fileName) => {
	for (let i = 0; i < files.length; i += 1) {
		const link = document.createElement('a');
		link.setAttribute('href', files[i].url);
		const urlSplitted = files[i].url.split('/');
		link.setAttribute('download', `${fileName} - ${urlSplitted.pop()}`);
		document.body.appendChild(link);
		link.click();
	}
};

export const snakeToCamel = str =>
	str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));

export const getDescFromEnum = (type, enumObj) => {
	let result = '';
	for (let i = 0; i < Object.entries(enumObj).length; i++) {
		const [value, key] = Object.entries(enumObj)[i];
		if (key === type) {
			result = snakeToCamel(value);
			break;
		}
	}
	return result;
};
export const moveElement = (array, oldIndex, newIndex) => {
	if (oldIndex < 0 || oldIndex >= array.length || newIndex < 0 || newIndex >= array.length) {
		console.log('Invalid index values.');
		return array;
	}
	const element = array.splice(oldIndex, 1)[0];
	array.splice(newIndex, 0, element);
	return array;
};

export const isEhrContext = () => {
	const currentUrlParams = new URL(window.location.href).searchParams;
	if (currentUrlParams && currentUrlParams.get('email') && currentUrlParams.get('ts') && currentUrlParams.get('s')) {
		return true;
	}

	return false;
};

export const getIntegrationTypes = arr =>
	arr.map(settingTypeId => {
		let label, value;
		switch (settingTypeId) {
			case IntegrationTypesSettings.TELEHEALTH:
				label = 'Telehealth';
				value = TeamConfigurationIntegrationTypes.TELEHEALTH;
				break;
			case IntegrationTypesSettings.EVIDEON:
				label = 'eVideon';
				value = TeamConfigurationIntegrationTypes.EVIDEON;
				break;
			case IntegrationTypesSettings.PCARE:
				label = 'pCare';
				value = TeamConfigurationIntegrationTypes.PCARE;
				break;
			case IntegrationTypesSettings.GET_WELL:
				label = 'GetWell';
				value = TeamConfigurationIntegrationTypes.GET_WELL;
				break;
			case IntegrationTypesSettings.SONIFI:
				label = 'Sonifi';
				value = TeamConfigurationIntegrationTypes.SONIFI;
				break;
			case IntegrationTypesSettings.MDM:
				label = 'MDM';
				value = TeamConfigurationIntegrationTypes.MDM;
				break;
			default:
				label = '';
				value = '';
		}
		return { label, value };
	});

export const doesParamValueExist = property => property && property.value !== '0';

export const getRoleValueById = (id, companySettings) => {
	const healthCareMsUserRoles = {
		[UserTypes.DOCTOR]: translate('doctor'),
		[UserTypes.NURSE]: companySettings.nurseDisplayName,
		[UserTypes.PATIENT]: translate('patient'),
		[UserTypes.ADMIN]: translate('admin'),
		[UserTypes.SUPER_USER]: translate('superUser'),
		[UserTypes.SUPER_ADMIN]: translate('superAdmin'),
		[UserTypes.OWNER]: translate('owner'),
		[UserTypes.DIGITAL_CLINICIAN]: translate('digitalClinician'),
		[UserTypes.FAMILY_MEMBER]: translate('familyMember'),
		[UserTypes.VIRTUAL_SITTER]: companySettings?.virtualSitterDisplayName ?? translate('virtualSitter'),
		[UserTypes.VISITOR]: translate('visitor'),
		[UserTypes.ROOM_SIGN]: translate('roomSign'),
		[UserTypes.SUPPORT]: translate('support'),
		[UserTypes.GUEST]: translate('guest'),
	};

	return healthCareMsUserRoles[id] ?? '';
};

export const getUserTypeBasedOnCompanyRole = companyRole => {
	switch (companyRole) {
		case CompanyRoles.DOCTOR:
			return UserTypes.DOCTOR;
		case CompanyRoles.VIRTUAL_CARE_PROVIDER:
			return UserTypes.NURSE;
		case CompanyRoles.VIRTUAL_SITTER:
			return UserTypes.VIRTUAL_SITTER;
		case CompanyRoles.PATIENT:
		case CompanyRoles.MEMBER:
			return UserTypes.PATIENT;
		case CompanyRoles.ADMIN:
			return UserTypes.ADMIN;
		case CompanyRoles.GUEST:
			return UserTypes.GUEST;
		case CompanyRoles.SUPER_USER:
			return UserTypes.SUPER_USER;
		case CompanyRoles.SUPER_ADMIN:
			return UserTypes.SUPER_ADMIN;
		case CompanyRoles.DIGITAL_CLINICIAN:
			return UserTypes.DIGITAL_CLINICIAN;
		case CompanyRoles.VISITOR:
			return UserTypes.VISITOR;
		case CompanyRoles.OWNER:
			return UserTypes.OWNER;
		case CompanyRoles.SUPPORT:
			return UserTypes.SUPPORT;
		default:
			return null;
	}
};

export const getCompanyRoleBasedOnUserType = role => {
	switch (role) {
		case UserTypes.NURSE:
			return CompanyRoles.VIRTUAL_CARE_PROVIDER;
		case UserTypes.ADMIN:
			return CompanyRoles.ADMIN;
		case UserTypes.SUPER_ADMIN:
			return CompanyRoles.SUPER_ADMIN;
		case UserTypes.SUPER_USER:
			return CompanyRoles.SUPER_USER;
		case UserTypes.DIGITAL_CLINICIAN:
			return CompanyRoles.DIGITAL_CLINICIAN;
		case UserTypes.DOCTOR:
			return CompanyRoles.DOCTOR;
		case UserTypes.VIRTUAL_SITTER:
			return CompanyRoles.VIRTUAL_SITTER;
		default:
			break;
	}
	return null;
};

export const getInviteStatus = inviteStatusId => {
	switch (inviteStatusId) {
		case InviteStatuses.SENT.type: {
			return translate('sentOnList');
		}
		case InviteStatuses.ACCEPTED.type: {
			return translate('registeredOnList');
		}
		case InviteStatuses.PENDING.type: {
			return translate('pendingOnList');
		}
		case InviteStatuses.CANCELLED.type: {
			return translate('cancelledOnList');
		}
		case InviteStatuses.FAILED.type: {
			return translate('failedOnList');
		}
		default:
			return '';
	}
};

export const isRedirectVirtualCareSession = () => {
	const redirectUri = getStorage().getItem('redirectUri');
	if (redirectUri) {
		return redirectUri.startsWith('/virtual-care-session/');
	}
	return false;
};

export const getAge = dateOfBirth => moment.utc().diff(moment.utc(dateOfBirth), 'years');

export const getRequestType = requestType => {
	switch (requestType) {
		case RequestType.RAPID:
			return CallRequestType.RAPID_REQUEST_TYPE;
		case RequestType.ADMISSION:
			return CallRequestType.ADMISSION_REQUEST_TYPE;
		default:
			return CallRequestType.REGULAR_REQUEST_TYPE;
	}
};

export const getRequestColor = requestType => {
	switch (requestType) {
		case RequestType.RAPID:
			return 'var(--red-1)';
		case RequestType.ADMISSION:
			return 'var(--orange-3)';
		default:
			return 'var(--blue-2)';
	}
};

export const getBase64 = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = readerEvent => {
			const image = new Image();
			image.onload = () => {
				// Resize the image
				const maxSize = 1080;
				const canvas = document.createElement('canvas');
				let { width, height } = image;
				if (width > height) {
					if (width > maxSize) {
						height *= maxSize / width;
						width = maxSize;
					}
				} else if (height > maxSize) {
					width *= maxSize / height;
					height = maxSize;
				}
				canvas.width = width;
				canvas.height = height;
				canvas.getContext('2d').drawImage(image, 0, 0, width, height);
				const dataUrl = canvas.toDataURL('image/jpeg');
				resolve(dataUrl);
			};
			// @ts-ignore
			image.src = readerEvent.target.result;
		};
		reader.onerror = error => {
			// eslint-disable-next-line no-console
			console.log(error);
			reject();
		};
	});
};

export const getSelectedBackgroundImage = async userSettings => {
	if (getUserRole() === UserRoles.GUEST) {
		return null;
	}
	const callBackground = userSettings[UserSettings.CALL_BACKGROUND];

	let uri = '';
	if (![VirtualBackgroundTypes.BLUR, VirtualBackgroundTypes.NONE].includes(callBackground) && callBackground) {
		const response = await getBlobSasPicture(callBackground, 'team-call-background-pictures');

		if (response.hasSucceeded) {
			uri = await imageUrlToBase64(response.result.uri.uri);
		}
	}
	return uri;
};

const getBackgroundImage = async ({ localParticipant, healthSystemId, background, backgroundImages, selectedCallBackground }) => {
	let result = { localParticipant, streamEffect: null, selectedBackground: VirtualBackgroundTypes.NONE };

	if (selectedCallBackground || backgroundImages.length > 0) {
		const image = backgroundImages.find(img => img.pictureUrl === background);
		result = {
			localParticipant,
			streamEffect: null,
			selectedBackground: null,
			backgroundUrl: selectedCallBackground || image.url,
		};
		return result;
	} else {
		const response = await getBackgroundImages(getCompanyId(), healthSystemId);
		if (!response.error && response.length > 0 && response.find(item => item.pictureUrl === background)) {
			const blobResponse = await getBlobSasPicture(background, 'team-call-background-pictures');
			if (!blobResponse.error) {
				result = {
					localParticipant,
					streamEffect: null,
					selectedBackground: null,
					backgroundUrl: blobResponse.result.uri.uri,
				};
			}
		}
		return result;
	}
};

export const getUserBackgroundParams = async ({
	localParticipant,
	userSettings = {},
	visualSettings = {},
	healthSystemId,
	backgroundImages,
	selectedCallBackground = null,
}) => {
	if (UserRoles.GUEST === getUserRole()) {
		return {
			localParticipant,
			streamEffect: null,
			selectedBackground:
				getStorage().getItem('virtualBackground') === 'blur' ? VirtualBackgroundTypes.BLUR : VirtualBackgroundTypes.NONE,
		};
	}
	const callBackground = userSettings[UserSettings.CALL_BACKGROUND];
	const backgroundConfig = getConfig(visualSettings[CompanySettings.DIGITAL_BACKGROUND]);
	const defaultHsBackground = visualSettings[CompanySettings.HS_DEFAULT_BACKGROUND]?.value;

	if (callBackground === VirtualBackgroundTypes.BLUR) {
		return { localParticipant, streamEffect: null, selectedBackground: VirtualBackgroundTypes.BLUR };
	}

	if (callBackground === VirtualBackgroundTypes.NONE) {
		return { localParticipant, streamEffect: null, selectedBackground: VirtualBackgroundTypes.NONE };
	}

	if (!backgroundConfig.value) {
		return { localParticipant, streamEffect: null, selectedBackground: VirtualBackgroundTypes.NONE };
	}

	if (![VirtualBackgroundTypes.NONE, VirtualBackgroundTypes.BLUR].includes(callBackground) && callBackground) {
		return await getBackgroundImage({
			localParticipant,
			healthSystemId,
			background: callBackground,
			backgroundImages,
			selectedCallBackground,
		});
	}

	if (defaultHsBackground === VirtualBackgroundTypes.BLUR) {
		return { localParticipant, streamEffect: null, selectedBackground: VirtualBackgroundTypes.BLUR };
	}

	if (defaultHsBackground === VirtualBackgroundTypes.NONE) {
		return { localParticipant, streamEffect: null, selectedBackground: VirtualBackgroundTypes.NONE };
	}

	if (![VirtualBackgroundTypes.NONE, VirtualBackgroundTypes.BLUR].includes(defaultHsBackground)) {
		return await getBackgroundImage({
			localParticipant,
			healthSystemId,
			background: defaultHsBackground,
			backgroundImages,
			selectedCallBackground,
		});
	}
	return {
		localParticipant,
		streamEffect: null,
		selectedBackground: VirtualBackgroundTypes.NONE,
	};
};

const mapBlob = async (pictureUrl, index, setConferenceErrorMessages) => {
	const response = await getBlobSasPicture(pictureUrl, 'team-call-background-pictures');
	if (response.error) {
		setConferenceErrorMessages();
		return null;
	}
	const imgToUpload = { url: response.result.uri.uri, id: index, pictureUrl };
	return imgToUpload;
};

export const fetchVirtualBackgroundImages = async (visualSettings, userSession, setConferenceErrorMessages) => {
	const backgroundConfig = getConfig(visualSettings[CompanySettings.DIGITAL_BACKGROUND]);
	if (getUserRole() === UserRoles.GUEST || !backgroundConfig.value) {
		return;
	}
	const response = await getBackgroundImages(getCompanyId(), userSession.healthSystem.id);
	if (response.error) {
		setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
		return;
	}

	const images = await Promise.all(response.map((item, index) => mapBlob(item.pictureUrl, index)));

	return images || [];
};

export const isJSON = text => {
	if (typeof text !== 'string') {
		return false;
	}
	try {
		JSON.parse(text);
		return true;
	} catch (error) {
		return false;
	}
};

export const getAudioDuration = (link, callback) => {
	const audio = new Audio(link);
	audio.addEventListener('loadedmetadata', () => {
		callback(audio.duration);
	});
	audio.load();
};

export const buildParamsFromArray = (paramName, array, isFirstParam) => {
	if (array === null) {
		return `${paramName}=null`;
	}

	if (array.length === 0) {
		return ``;
	}

	return array.reduce((acc, item, index) => {
		const separator = isFirstParam && index === 0 ? '' : '&';

		return `${acc}${separator}${paramName}=${item}`;
	}, '');
};

export const buildQueryString = obj => {
	const nonFalsyProperties = Object.fromEntries(Object.entries(obj).filter(([key, value]) => value));

	const queryString = Object.entries(nonFalsyProperties)
		.map(([key, value]) => `${encodeURIComponent(_.kebabCase(key))}=${encodeURIComponent(value)}`)
		.join('&');

	return queryString;
};

export const getIsOldUserExperience = (companyConfigurations, hsSettings) => {
	const isOldExperienceCompany = getConfigurationValue(companyConfigurations[GeneralAndMenuSettings.IS_OLD_USER_EXPERIENCE]);
	const isOldExperienceHs =
		hsSettings.find(item => item.settingTypeId === GeneralAndMenuSettings.IS_OLD_USER_EXPERIENCE)?.value === 'true';
	const isExperienceHsChangeable = getConfigurationValue(
		companyConfigurations[GeneralAndMenuSettings.IS_EXPERIENCE_HS_CHANGEABLE]
	);
	return isExperienceHsChangeable && isOldExperienceCompany ? isOldExperienceHs : isOldExperienceCompany;
};

export const getAiSettingsConfigurations = (
	adminRoomSettings,
	nurseRoomSettings,
	callWorkflowTypeId = CallWorkflowType.MONITORING
) => {
	const response = { configs: {}, error: null };
	const allMenus = {};

	if (adminRoomSettings.error) {
		response.error = adminRoomSettings.error.message;
	} else {
		adminRoomSettings.settings.forEach(item => {
			const config = configurableAISettings()[getConfigurationWithDiffTypeKey(item.settingTypeId, item.callWorkflowTypeId)];
			if (config && item.callWorkflowTypeId === callWorkflowTypeId) {
				config.value = JSON.parse(item.value);
				config.isLocked = item.isLocked;
				if (item.variant && config.options) {
					config.variant = config.options.find(opt => opt.value === item.variant);
				}
				allMenus[item.settingTypeId] = config;
			}
		});
	}
	if (nurseRoomSettings.error) {
		response.error = nurseRoomSettings.error.message;
	} else {
		nurseRoomSettings.forEach(item => {
			if (allMenus[item.preferenceTypeId] && !allMenus[item.preferenceTypeId].isLocked) {
				allMenus[item.preferenceTypeId].value = JSON.parse(item.value);
			}
		});
	}

	response.configs = allMenus;
	return response;
};
export const groupedAttachments = attachments =>
	attachments.reduce((acc, attachment) => {
		const key = attachment.dateDifference;

		if (!acc[key]) {
			acc[key] = [];
		}

		acc[key].push(attachment);

		return acc;
	}, {});

export const getFailedInvitationMessage = participant => {
	if (participant.reason === InviteParticipantFailureReason.INVITE_DENIED) {
		return translate('participantInviteDenied', { value: participant.name });
	}
	return participant.name
		? translate('unableToGetInfo', { value: participant.name })
		: translate('unableToGetInfoGeneralMessage');
};

export const hasNonEmptyValues = obj => {
	if (typeof obj !== 'object' || obj === null) {
		return false;
	}
	const keys = Object.keys(obj);

	return (
		keys.length > 0 &&
		keys.some(key => {
			const value = obj[key];
			return value !== null && value !== '' && value !== undefined;
		})
	);
};

export const isValidJSON = jsonString => {
	try {
		const obj = JSON.parse(jsonString);
		return (typeof obj === 'object' && obj !== null) || Array.isArray(obj);
	} catch (e) {
		return false;
	}
};

export const isObjectValue = value => value instanceof Object && value.constructor === Object;

export const exportArrayToCsv = (exportData = [], fileName, sheetName = '') => {
	const workBook = utils.book_new();
	const workSheet = utils.json_to_sheet(exportData);
	utils.book_append_sheet(workBook, workSheet, sheetName);
	return writeFile(workBook, `${fileName}.xlsx`);
};

export const setSimplifiedLanguageLabels = (languages = []) => {
	const languageMap = languages.reduce((acc, { localeName }) => {
		const baseLanguage = localeName?.split(' (')[0];
		if (!acc[baseLanguage]) {
			acc[baseLanguage] = 0;
		}
		acc[baseLanguage] += 1;
		return acc;
	}, {});

	return languages.map(language => {
		const baseLanguage = language?.localeName?.split(' (')[0];
		const hasMultipleDialects = languageMap[baseLanguage] > 1;
		return {
			...language,
			label: hasMultipleDialects ? language.localeName : baseLanguage,
			value: language.id,
		};
	});
};

export const getFontColor = isDarkMode => (isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayFive);

export const arrayBufferToBase64 = arrayBuffer => {
	const bytes = new Uint8Array(arrayBuffer);
	let binaryString = '';
	for (let i = 0; i < bytes.byteLength; i++) {
		binaryString += String.fromCharCode(bytes[i]);
	}
	return `data:image/jpeg;base64,${btoa(binaryString)}`;
};

export const containsLetters = input => {
	const regex = /[a-zA-Z]/;
	return regex.test(input);
};

export const isValidUrl = string => /^(https?:\/\/)?(www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/.test(string);

export const isThumbnailUrl = url => {
	try {
		const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
		if (imageExtensions.some(ext => url.endsWith(ext))) {
			return true;
		}
		return false;
	} catch (error) {
		return false;
	}
};

export const getObjectById = (id, object) => {
	for (const key in object) {
		if (object[key].id === id) {
			return object[key];
		}
	}
	return null;
};

/**
 * Get Monitoring feed bitrate config
 * @param {string} tenantId
 */
export const getVideoBitrateConfig = tenantId => {
	let videoBitrateConfig = [
		{ maxFeeds: 1, maxBitrate: 2500, minBitrate: 1500 },
		{ maxFeeds: 4, maxBitrate: 700, minBitrate: 500 },
		{ maxFeeds: 9, maxBitrate: 500, minBitrate: 400 },
		{ maxFeeds: 16, maxBitrate: 400, minBitrate: 300 },
		{ maxFeeds: 24, maxBitrate: 300, minBitrate: 200 },
		{ maxFeeds: 32, maxBitrate: 200, minBitrate: 100 },
	];

	if (tenantId === APP_CONFIG.mayoTenantId) {
		videoBitrateConfig = [
			{ maxFeeds: 1, maxBitrate: 5000, minBitrate: 2500 },
			{ maxFeeds: 4, maxBitrate: 5000, minBitrate: 2500 },
			{ maxFeeds: 9, maxBitrate: 5000, minBitrate: 2500 },
			{ maxFeeds: 16, maxBitrate: 5000, minBitrate: 2500 },
			{ maxFeeds: 24, maxBitrate: 5000, minBitrate: 2500 },
			{ maxFeeds: 32, maxBitrate: 5000, minBitrate: 2500 },
		];
	}

	return videoBitrateConfig;
};
